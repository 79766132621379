import { Box, Button, CircularProgress, Container, Typography } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import useScreenOrientation from '../lib/hooks/useScreenOrientation';
import { useTranslation } from "react-i18next";

export default function Home() {

  const navigate = useNavigate();

  //@ts-ignore
  const { user, login } = useAuth();

  const orientation = useScreenOrientation();

  const [artworkLink, setArtworkLink] = useState("");
  const [artworkName, setArtworkName] = useState("");
  const [artworkInfo, setArtworkInfo] = useState("");
  const [musicLink, setMusicLink] = useState("");

  const {t} = useTranslation();

  async function getArtworkLink() {
    var url = "" + process.env.REACT_APP_API_SERVER + "art/artwork";

    try {
      var res = await fetch(
        url, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
      }
      )

      const data = await res.json()

      if (data) {
        if (data["link"]) {
          setArtworkLink(data["link"])
        }
        if (data["info"]) {
          setArtworkInfo(data["info"])
        }
        if (data["name"]) {
          setArtworkName(data["name"])
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function getMusicLink() {
    var url = "" + process.env.REACT_APP_API_SERVER + "art/music";

    try {
      var res = await fetch(
        url, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
      }
      )

      const data = await res.json()

      if (data && data["link"]) {
        setMusicLink(data["link"])
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (artworkLink === "") {
      getArtworkLink();
    }
    if (musicLink === "") {
      getMusicLink();
    }
  }, [])

  return (
    <Box className="home" display="flex" flexDirection={orientation === "landscape-primary" || orientation === "landscape-secondary" ? "row" : "column"} minHeight="100%">
      <Box className="main-flex" flex="2" display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ mt: orientation === "landscape-primary" || orientation === "landscape-secondary" ? 0 : "3rem" }}>
        <Typography variant='h5' textAlign="center" fontWeight={500} sx={{ mb: "1rem", color: "var(--tetriary-color)" }}>{t('aotdTitle')}</Typography>
        {artworkLink && (
          <Box className="aotd" component="img" src={artworkLink ?? require("../images/defaultArtwork.jpg")} sx={{
            height: "60%",
            width: "80%",
            boxShadow: "-50px -50px 0 -40px var(--secondary-color),50px 50px 0 -40px var(--secondary-color)",
            objectFit: "cover",
          }}></Box>
        )}
        {!artworkLink && (
          <CircularProgress color='secondary' size='4rem' />
        )}
        <Typography variant='h6' textAlign="center" sx={{ color: "var(--tetriary-color)", mt: "0.5rem" }}>{artworkName}</Typography>
        <Typography variant='body2' textAlign="center" sx={{ color: "var(--tetriary-color)" }}>{artworkInfo}</Typography>
      </Box>
      <Box className="main-flex" flex={orientation === "landscape-primary" || orientation === "landscape-secondary" ? "3" : "1"} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box className="branding" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Box
            component="img"
            sx={{
              height: "3.5rem",
              mt: "-0.1rem",
              mr: "0.25rem",
              border: "3px solid rgba(84,40,51, 0.7)",
              borderRadius: "7px",
            }}
            alt="Arcinator Logo."
            src={require("../images/logoFullFull.png")}
          />
          <Box className="title-cont" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography
              variant='h3'
              fontFamily="Luckiest Guy"
              fontWeight={700}
              textAlign="center"
              sx={{
                color: 'var(--secondary-color)',
                textShadow: 'rgb(84, 40, 51) 3px 0px 0px, rgb(84, 40, 51) 2.83487px 0.981584px 0px, rgb(84, 40, 51) 2.35766px 1.85511px 0px, rgb(84, 40, 51) 1.62091px 2.52441px 0px, rgb(84, 40, 51) 0.705713px 2.91581px 0px, rgb(84, 40, 51) -0.287171px 2.98622px 0px, rgb(84, 40, 51) -1.24844px 2.72789px 0px, rgb(84, 40, 51) -2.07227px 2.16926px 0px, rgb(84, 40, 51) -2.66798px 1.37182px 0px, rgb(84, 40, 51) -2.96998px 0.42336px 0px, rgb(84, 40, 51) -2.94502px -0.571704px 0px, rgb(84, 40, 51) -2.59586px -1.50383px 0px, rgb(84, 40, 51) -1.96093px -2.27041px 0px, rgb(84, 40, 51) -1.11013px -2.78704px 0px, rgb(84, 40, 51) -0.137119px -2.99686px 0px, rgb(84, 40, 51) 0.850987px -2.87677px 0px, rgb(84, 40, 51) 1.74541px -2.43999px 0px, rgb(84, 40, 51) 2.44769px -1.73459px 0px, rgb(84, 40, 51) 2.88051px -0.838247px 0px'
              }}
            >ARCINATOR</Typography>
            <Typography
              variant='h6'
              fontFamily="Luckiest Guy"
              fontWeight={700}
              textAlign="center"
              sx={{
                mt: "-0.8rem",
                color: 'var(--tetriary-color)',
                textShadow: 'rgb(84, 40, 51) 3px 0px 0px, rgb(84, 40, 51) 2.83487px 0.981584px 0px, rgb(84, 40, 51) 2.35766px 1.85511px 0px, rgb(84, 40, 51) 1.62091px 2.52441px 0px, rgb(84, 40, 51) 0.705713px 2.91581px 0px, rgb(84, 40, 51) -0.287171px 2.98622px 0px, rgb(84, 40, 51) -1.24844px 2.72789px 0px, rgb(84, 40, 51) -2.07227px 2.16926px 0px, rgb(84, 40, 51) -2.66798px 1.37182px 0px, rgb(84, 40, 51) -2.96998px 0.42336px 0px, rgb(84, 40, 51) -2.94502px -0.571704px 0px, rgb(84, 40, 51) -2.59586px -1.50383px 0px, rgb(84, 40, 51) -1.96093px -2.27041px 0px, rgb(84, 40, 51) -1.11013px -2.78704px 0px, rgb(84, 40, 51) -0.137119px -2.99686px 0px, rgb(84, 40, 51) 0.850987px -2.87677px 0px, rgb(84, 40, 51) 1.74541px -2.43999px 0px, rgb(84, 40, 51) 2.44769px -1.73459px 0px, rgb(84, 40, 51) 2.88051px -0.838247px 0px'
              }}
            >{t('arcinatorSub')}</Typography>
          </Box>
        </Box>
        <Box className="home-buttons" display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ width: "18rem", mt: "0.4rem" }}>
          <Button variant='contained' sx={{
            mr: "2rem",
            bgcolor: "var(--tetriary-color)",
            color: "var(--primary-color)",
            width: "8rem",
            height: "2rem",
            fontSize: "2rem",
            "&:hover": {
              bgcolor: "var(--secondary-color)",
              color: "var(--tetriary-color)"
            }
          }} onClick={() => navigate("/demo")}>
            <Typography variant='body2'
            >{t("tryButton")}</Typography>
          </Button>
          {user && (
            <Button variant='contained' sx={{
              bgcolor: "var(--tetriary-color)",
              color: "var(--primary-color)",
              width: "8rem",
              height: "2rem",
              "&:hover": {
                bgcolor: "var(--secondary-color)",
                color: "var(--tetriary-color)"
              }
            }} onClick={() => navigate("/chat")}>
              <Typography variant='body2'
              >{t("chatButton")}</Typography>
            </Button>
          )}
          {!user && (
            <Button variant='contained' sx={{
              bgcolor: "var(--tetriary-color)",
              color: "var(--primary-color)",
              width: "8rem",
              height: "2rem",
              "&:hover": {
                bgcolor: "var(--secondary-color)",
                color: "var(--tetriary-color)"
              }
            }} onClick={() => navigate("/sign-up")}>
              <Typography variant='body2'
              >Sign-up</Typography>
            </Button>
          )}
        </Box>
      </Box>
      <Box className="main-flex" flex="2" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant='h5' textAlign="center" fontWeight={500} sx={{ mb: "1rem", color: "var(--tetriary-color)" }}>Music Piece of The Day</Typography>
        {musicLink && (
          <Box className="aotd" component="iframe" src={musicLink} sx={{
            height: "60%",
            width: "80%",
            border: "0px"
          }}></Box>
        )}
        {!musicLink && (
          <CircularProgress color='secondary' size='4rem' />
        )}
      </Box>
    </Box>
  )
}
