import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LoadingCheck from '../components/LoadingCheck'
import { RequestAndAnswerPair } from '../types/Message'
import { useAuth } from '../context/AuthProvider'

import './Demo.css'
import useScreenOrientation from '../lib/hooks/useScreenOrientation'
import InputBar from '../components/InputBar'
import MessagePair from '../components/MessagePair'
import { useTranslation } from 'react-i18next'

export default function Demo() {
  const [initialLoad, setInitialLoad] = useState(true);

  const {t} = useTranslation()

  const localMessagesString = localStorage.getItem("demoMessages")
  let localMessages: RequestAndAnswerPair[] = []
  if (!localMessagesString || localMessagesString === "") {
    localStorage.setItem("demoMessages", JSON.stringify(localMessages))
  } else {
    localMessages = JSON.parse(localMessagesString)
  }

  //@ts-ignore
  const { user, profile } = useAuth()

  const [messages, setMessages] = useState<RequestAndAnswerPair[]>(localMessages)

  useEffect(() => {
    localStorage.setItem("demoMessages", JSON.stringify(messages))

    const div = document.getElementById("msgWndw") as HTMLDivElement
    div.scrollTop = div.scrollHeight;
  }, [messages])

  function onInputKeyDown(event: any) {
    if (event.key === "Enter") {
      sendMessageDemo()
    }
  }

  async function sendMessageDemo() {

    const messageInputField: HTMLInputElement = document.getElementById("messageInputField") as HTMLInputElement;
    const question = messageInputField.value
    messageInputField.value = ""

    if (question === "") return;

    //TODO: update url when eren adds method
    const url = "" + process.env.REACT_APP_API_SERVER + "conversation/demo?question=" + encodeURIComponent(question)

    let output: string

    let message = {
      question: question,
      output: "loading",
    } as RequestAndAnswerPair

    setMessages(n => n.concat(message))

    try {
      const res = await fetch(
        url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
        }
        )

      const data = await res.json();

      output = data["answer"] ?? t('errorText')
    } catch (err) {
      console.log(err)
      output = t('errorText')
    }

    setMessages(n => n.filter(msg => msg !== message))

    message = {
      question: question,
      output: output
    } as RequestAndAnswerPair

    setMessages(n => n.concat(message))

    setInitialLoad(false);
  }



  function deleteMessage(message: RequestAndAnswerPair) {
    setMessages(a => a.filter(msg => msg !== message))
    setInitialLoad(true)
  }

  return (
    <Box className="demo" width="100%" height="100%" justifyContent="flex-start" alignItems="center" display="flex" flexDirection="column" position="relative">
      <Box className="chat-window" maxWidth="60%" minWidth="50%" height="100%" sx={{ mb: "1rem"}}>
        <Box className="message-window" id="msgWndw" width="100%" sx={{ overflowX: "hidden", overflowY: "auto"}}>
          <List className="messages">
            {messages.map((message, index) => (
              <MessagePair key={index} message={message} deleteFunction={deleteMessage} typewrite={index === messages.length-1 && !initialLoad}></MessagePair>
            )
            )}
          </List>
        </Box>
        <InputBar keyDownFunction={onInputKeyDown} sendFunction={sendMessageDemo}></InputBar>
      </Box>
    </Box>
  )
}
