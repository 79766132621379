import { Box, Divider, Link, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import useScreenOrientation from '../lib/hooks/useScreenOrientation'

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'

import './About.css'
import { useTranslation } from 'react-i18next';

type Card = {
  title: string,
  img: any,
  children: React.ReactNode
}

export default function About() {
  
  const {t} = useTranslation();

  const orientation = useScreenOrientation();

  const cards: Card[] = [
    {
      title: t("cardAboutTitle"),
      img: '/images/arc6014.png',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardAboutDesc1')}<Link href="https://arc6014.com" color="secondary">{t('cardAboutDescLink')}</Link>{t('cardAboutDesc2')}</p>
      </Box>
    },
    {
      title: t("cardSourceTitle"),
      img: '/images/sources.jpg',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardSourceDesc')}</p>
        <ul style={{ fontSize: "0.6rem", marginTop: 0 }}>
          <li><Link href="https://www.firstinspires.org/resource-library/frc/archived-game-documentation" color="#4169e1">{t('cardSource1')}</Link></li>
          <li><Link href="https://docs.wpilib.org/en/stable/" color="#4169e1">{t('cardSource2')}</Link></li>
          <li><Link href="https://www.thebluealliance.com/" color="#4169e1">{t('cardSource3')}</Link></li>
        </ul>
      </Box>
    },
    {
      title: t("cardLangTitle"),
      img: '/images/languages.webp',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardLangDesc')}</p>
      </Box>
    },
    {
      title: t("cardDiscTitle"),
      img: '/images/discord.png',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardDiscDesc1')}<Link color="secondary" href="https://discord.com/api/oauth2/authorize?client_id=1186646141018849369&permissions=380104927296&scope=bot" target="_blank">{t('cardDiscDescLink')}</Link>{t('cardDiscDesc2')}</p>
      </Box>
    },
    {
      title: t("cardOTDTitle"),
      img: '/images/defaultArtwork.jpg',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardOTDDesc1')}<Link href="/" color="secondary">{t('cardOTDDescLink')}</Link>{t('cardOTDDesc2')}</p>
      </Box>
    },
    {
      title: t("cardDisclaimerTitle"),
      img: '/images/disclaimer.jpg',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardDisclaimerDesc')}</p>
      </Box>
    },
    {
      title: t("cardContTitle"),
      img: '/images/contact.png',
      children: <Box sx={{ width: "100%", mt: "-0.5rem", zIndex: "1" }}>
        <p style={{ fontSize: "0.6rem ", marginBottom: 0 }}>{t('cardContDesc1')}<Link color="secondary" href="https://forms.gle/zAgVG7q3hCXbDBRcA" target="_blank">{t('cardContDescLink')}</Link>{t('cardContDesc2')}</p>
      </Box>
    },
  ]

  return (
    <Box className="about" display="flex" flexDirection="column" alignItems="center" sx={{ width: "100%", height: "100%" }} justifyContent={orientation === "landscape-primary" || orientation === "landscape-secondary" ? "start" : "center"}>
      <Box className="branding" display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ mt: "2rem" }}>
        <Box
          component="img"
          sx={{
            height: "3.5rem",
            mt: "-0.1rem",
            mr: "0.25rem",
            border: "3px solid rgba(84,40,51, 0.7)",
            borderRadius: "7px",
          }}
          alt="Arcinator Logo."
          src={require("../images/logoFullFull.png")}
        />
        <Box className="title-cont" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography
            variant='h3'
            fontFamily="Luckiest Guy"
            fontWeight={700}
            textAlign="center"
            sx={{
              color: 'var(--secondary-color)',
              textShadow: 'rgb(84, 40, 51) 3px 0px 0px, rgb(84, 40, 51) 2.83487px 0.981584px 0px, rgb(84, 40, 51) 2.35766px 1.85511px 0px, rgb(84, 40, 51) 1.62091px 2.52441px 0px, rgb(84, 40, 51) 0.705713px 2.91581px 0px, rgb(84, 40, 51) -0.287171px 2.98622px 0px, rgb(84, 40, 51) -1.24844px 2.72789px 0px, rgb(84, 40, 51) -2.07227px 2.16926px 0px, rgb(84, 40, 51) -2.66798px 1.37182px 0px, rgb(84, 40, 51) -2.96998px 0.42336px 0px, rgb(84, 40, 51) -2.94502px -0.571704px 0px, rgb(84, 40, 51) -2.59586px -1.50383px 0px, rgb(84, 40, 51) -1.96093px -2.27041px 0px, rgb(84, 40, 51) -1.11013px -2.78704px 0px, rgb(84, 40, 51) -0.137119px -2.99686px 0px, rgb(84, 40, 51) 0.850987px -2.87677px 0px, rgb(84, 40, 51) 1.74541px -2.43999px 0px, rgb(84, 40, 51) 2.44769px -1.73459px 0px, rgb(84, 40, 51) 2.88051px -0.838247px 0px'
            }}
          >ARCINATOR</Typography>
          <Typography
            variant='h6'
            fontFamily="Luckiest Guy"
            fontWeight={700}
            textAlign="center"
            sx={{
              mt: "-0.8rem",
              color: 'var(--tetriary-color)',
              textShadow: 'rgb(84, 40, 51) 3px 0px 0px, rgb(84, 40, 51) 2.83487px 0.981584px 0px, rgb(84, 40, 51) 2.35766px 1.85511px 0px, rgb(84, 40, 51) 1.62091px 2.52441px 0px, rgb(84, 40, 51) 0.705713px 2.91581px 0px, rgb(84, 40, 51) -0.287171px 2.98622px 0px, rgb(84, 40, 51) -1.24844px 2.72789px 0px, rgb(84, 40, 51) -2.07227px 2.16926px 0px, rgb(84, 40, 51) -2.66798px 1.37182px 0px, rgb(84, 40, 51) -2.96998px 0.42336px 0px, rgb(84, 40, 51) -2.94502px -0.571704px 0px, rgb(84, 40, 51) -2.59586px -1.50383px 0px, rgb(84, 40, 51) -1.96093px -2.27041px 0px, rgb(84, 40, 51) -1.11013px -2.78704px 0px, rgb(84, 40, 51) -0.137119px -2.99686px 0px, rgb(84, 40, 51) 0.850987px -2.87677px 0px, rgb(84, 40, 51) 1.74541px -2.43999px 0px, rgb(84, 40, 51) 2.44769px -1.73459px 0px, rgb(84, 40, 51) 2.88051px -0.838247px 0px'
            }}
          >{t('arcinatorSub')}</Typography>
        </Box>
      </Box>
      <Box className="info" padding="0 10%" sx={{ color: "var(--tetriary-color)" }}>
        <Typography>
          {t("aboutDesc")}
        </Typography>
      </Box>
      {(orientation === "landscape-primary" || orientation === "landscape-secondary" || true) && (
        <Box className="carausel" display="flex" flexDirection="row" sx={{
          width: orientation === "landscape-primary" || orientation === "landscape-secondary" ? "39.75rem" : "26.75rem",
          mt: "1rem",
          overflowX: "auto",
          justifyContent: "center",
          position: "relative",
        }}>
          <Swiper
            modules={[Pagination, Autoplay, A11y, Navigation, Scrollbar]}
            spaceBetween={10}
            slidesPerView={orientation === "landscape-primary" || orientation === "landscape-secondary" ? 3 : 2}
            pagination={{ clickable: true }}
            navigation={orientation === "landscape-primary" || orientation === "landscape-secondary"}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop
            style={{
              //this error doesnt really mean anything so...
              //@ts-ignore
              "--swiper-pagination-color": "var(--secondary-color)",
              "--swiper-navigation-color": "var(--secondary-color)",
            }}
          >
            {cards.map((card) => (
              <SwiperSlide>
                <Paper elevation={3} className='carausel-card' sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "12rem",
                  minWidth: "12rem",
                  height: "15rem",
                  position: "relative",
                  padding: "0 0.5rem",
                }}>
                  <Box sx={{
                    position: "absolute",
                    top: "0",
                    width: "100%",
                    height: "7rem",
                    borderRadius: "4px 4px 0 0",
                    backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(" + card.img + ")",
                    backgroundSize: "cover",
                  }}></Box>
                  <Typography variant='subtitle1' fontWeight={700} sx={{ mt: "5rem", zIndex: "1" }}>{card.title}</Typography>
                  <Divider variant="inset" flexItem component="div" sx={{ ml: "5%", width: "90%", zIndex: "1" }}></Divider>
                  {card.children}
                </Paper>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </Box>
  )
}
