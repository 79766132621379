import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthProvider from './context/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material';
import enJSON from './locale/en.json'

i18next.init({
  interpolation: { escapeValue: false },
  resources: {
    en: { ...enJSON}
  },
  lng: "en"
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#222222',
      light: '#191919',
      dark: '#131313',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#d76198',
      light: '#dd8bad',
      contrastText: '#ffffff',
    }
  }
});

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <AuthProvider>
            <ThemeProvider theme={theme}>
              <App></App>
            </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
