import { Paper, ListItem, IconButton, ListItemText, Avatar, ListItemAvatar, CircularProgress } from '@mui/material'
import { profile } from 'console'
import React from 'react'
import { RequestAndAnswerPair } from '../types/Message'

import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../context/AuthProvider';
import { BouncingLoader } from './Loaders';
import Typewriter from './Typewriter';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { Message } from '@mui/icons-material';

import './MessagePair.css'
import { MarkdownRenderer } from './MarkdownRenderer';

interface Props {
    message: RequestAndAnswerPair,
    deleteFunction: Function,
    typewrite?: Boolean,
}

export default function MessagePair(props: Props) {
    //@ts-ignore
    const { user, profile } = useAuth()

    return (
        <>
            <Paper sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", maxWidth: "100%", ml: "auto", bgcolor: "rgba(var(--primary-color-vals), 0.5)", padding: "0.25rem", mb: "0.5rem" }}>
                <ListItem>
                    <IconButton edge="end" aria-label="delete">
                    </IconButton>
                    <ListItemText className='message-text' sx={{ color: "var(--tetriary-color)", textAlign: "right" }}
                        primary={props.message.question}
                    />
                </ListItem>
                <Avatar alt={profile?.username ?? "Your Avatar"} src={profile?.avatar_url ?? require("../images/user.png")} sx={{ mr: "0.5rem" }} />
            </Paper>
            <Paper sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", maxWidth: "100%", mr: "auto", bgcolor: "rgba(var(--primary-color-vals), 0.5)", padding: "0.25rem", mb: "0.5rem" }}>
                {props.message.output !== "loading" && (
                    <ListItem className="message"
                        sx={{overflow:"hidden"}}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => props.deleteFunction(props.message)}>
                                <DeleteIcon sx={{
                                    color: "var(--secondary-color)", "&:hover": {
                                        color: "var(--secondary-color-accent)",
                                        transform: "scale(1.1)"
                                    }
                                }} />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            <Avatar className="avatar" alt="arcinator" src={require("../images/logoFull.png")} sx={{
                                height: "2.75rem",
                                width: "2.75rem",
                                mr: "0.5rem"
                            }} />
                        </ListItemAvatar>
                        <ListItemText className='message-text' sx={{ color: "var(--tetriary-color)", overflowX: "auto" }}
                            primary={!props.typewrite 
                                ? (
                                    <span>
                                        <MarkdownRenderer>{props.message.output}</MarkdownRenderer>
                                    </span>
                                )
                                : (
                                    <Typewriter text={props.message.output} delay={25/(Math.floor(props.message.output.length / 200)+1)}></Typewriter>
                                )
                            }
                        />
                    </ListItem>
                )}
                {props.message.output === "loading" && (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="avatar" alt="arcinator" src={require("../images/logoFull.png")} sx={{
                                height: "2.75rem",
                                width: "2.75rem",
                                mr: "0.5rem"
                            }} />
                        </ListItemAvatar>
                        <BouncingLoader />
                    </ListItem>
                )}
            </Paper>
        </>
    )
}
