import { Alert, Box, Button, IconButton, Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import LoadingCheck from '../components/LoadingCheck'
import { useAuth } from '../context/AuthProvider';

import CloseIcon from '@mui/icons-material/Close';
import { supabase } from '../lib/helper/supabaseClient';
import { useTranslation } from 'react-i18next';

enum Status {
  error,
  success,
  none,
}

export default function Account() {

  const {t} = useTranslation()

  const [loading, setLoading] = useState(false);

  //@ts-ignore
  const { user } = useAuth();

  const [status, setStatus] = useState(Status.none);

  return (
    <Box className="account" height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <LoadingCheck open={!user || loading}></LoadingCheck>
      <Box className="acnt" width="fit-content" alignItems="center" display="flex" flexDirection="column">
        <Paper sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <TextField variant='outlined' disabled type="email" value={user?.email} sx={{ margin: "0.5rem 0.5rem 0 0.5rem" }}></TextField>
          <TextField variant='outlined' disabled type="password" value={"00000000"} sx={{ margin: "0.5rem" }}></TextField>
        </Paper>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: "100% !important",
            mt: "0.5rem",
          }}
          onClick={async () => {
            setLoading(true)
            const { data, error } = await supabase.auth.resetPasswordForEmail(user?.email, {
              redirectTo: window.location.origin + "/sign-up",
            });
            if (error) {
              setStatus(Status.error)
            } else {
              setStatus(Status.success)
            }
            setLoading(false)
          }}
        >
          {t('updatePwd')}
        </Button>
        {status != Status.none && (
          <Alert
            severity={status == Status.success ? "success" : "error"}
            sx={{ width: "calc(100% - 1rem)", mt: "0.5rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "0.5rem" }}
            action={
              <IconButton size="small" onClick={() => setStatus(Status.none)}>
                <CloseIcon sx={{ height: "1rem", width: "1rem" }}></CloseIcon>
              </IconButton>
            }
          >
            {status == Status.success
              ? t('pwdEmail')
              : t('errorText')}
          </Alert>
        )}
        <Box className="acnt-buttons" width="19rem" alignItems="center" justifyContent="center" display="flex" flexDirection="row" sx={{mt: "0.5rem"}}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              flex: 1,
              width: "fit-content",
              mr: "0.5rem",
              height: "100%",
            }}
            onClick={() => supabase.auth.signOut()}
          >
            {t('logout')}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              flex: 1,
              width: "fit-content !important",
              height: "100%",
            }}
            onClick={ async () => {
              if(window.confirm(t('delConf'))) {
                var url = "" + process.env.REACT_APP_API_SERVER + "account/delete?uid=" + encodeURIComponent(user.id)
              await fetch(url, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
              })
              supabase.auth.signOut();
              }
            }}
          >
            {t('delAcc')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
