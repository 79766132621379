import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthProvider'
import { Box, List } from '@mui/material'
import LoadingCheck from '../components/LoadingCheck'
import InputBar from '../components/InputBar'
import MessagePair from '../components/MessagePair'
import { RequestAndAnswerPair } from '../types/Message'
import { User } from '@supabase/supabase-js'
import { supabase } from '../lib/helper/supabaseClient'
import { useTranslation } from 'react-i18next'

export default function Chat() {

  const [initialLoad, setinitialLoad] = useState(true)

  const {t} = useTranslation()

  //@ts-ignore
  const { user } = useAuth()

  const [loading, setLoading] = useState(true)

  const [messages, setMessages] = useState<RequestAndAnswerPair[]>([])

  useEffect(() => {
    if (user) {
      getMessages(user);
    }
  }, [user])

  useEffect(() => {
    const div = document.getElementById("msgWndw") as HTMLDivElement
    div.scrollTop = div.scrollHeight;
  }, [messages])

  async function getMessages(user: User) {
    setLoading(true)
    let newMessages: RequestAndAnswerPair[] = [];

    //@ts-ignore
    let id = user.id;

    let { data, error } = await supabase
      .from("conversation_logging")
      .select()
      .eq("uid", id);

    if (data) {
      data.forEach((e) => {
        newMessages = newMessages.concat({
          question: e["question"],
          output: e["output"],
          id: e["id"]
        } as RequestAndAnswerPair);
      });

      setMessages(newMessages);
    }
    setLoading(false)
  }

  function onInputKeyDown(event: any) {
    if (event.key === "Enter") {
      sendMessageChat()
    }
  }

  async function sendMessageChat() {

    const messageInputField: HTMLInputElement = document.getElementById("messageInputField") as HTMLInputElement;
    const question = messageInputField.value
    messageInputField.value = ""

    if (question === "") return;

    const url = "" + process.env.REACT_APP_API_SERVER + "conversation/" + user.id + "?question=" + encodeURIComponent(question)

    let output: string;
    let id: Number | null = null;

    let message = {
      question: question,
      output: "loading",
    } as RequestAndAnswerPair

    setMessages(n => n.concat(message))

    try {
      const res = await fetch(
        url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
        }
      )

      const data = await res.json();

      output = data["answer"] ?? t('errorText')
      id = data["id"]
    } catch (err) {
      console.log(err)
      output = t('errorText')
    }

    setMessages(n => n.filter(msg => msg !== message))

    message = {
      question: question,
      output: output,
      id: id,
    } as RequestAndAnswerPair

    setMessages(n => n.concat(message))

    setinitialLoad(false)
  }



  async function deleteMessage(message: RequestAndAnswerPair) {
    if (!message.id) {
      await getMessages(user)
    }

    await supabase.from('conversation_logging').delete().eq('id', message.id)
    await getMessages(user)

    setinitialLoad(true)
  }

  return (
    <Box className="demo" width="100%" height="100%" justifyContent="flex-start" alignItems="center" display="flex" flexDirection="column" position="relative">
      <LoadingCheck open={loading || user===null}></LoadingCheck>
      <Box className="chat-window" maxWidth="60%" minWidth="50%" height="100%" sx={{ mb: "1rem"}}>
        <Box className="message-window" id="msgWndw" width="100%" sx={{ overflowX: "hidden", overflowY: "auto"}}>
          <List className="messages">
            {messages.map((message, index) => (
              <MessagePair key={index} message={message} deleteFunction={deleteMessage} typewrite={index === messages.length-1 && !initialLoad}></MessagePair>
            )
            )}
          </List>
        </Box>
        <InputBar keyDownFunction={onInputKeyDown} sendFunction={sendMessageChat}></InputBar>
      </Box>
    </Box>
  )
}
