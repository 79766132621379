import { Facebook, Instagram, Twitter } from '@mui/icons-material'
import { BottomNavigation, Box, Button, Container, Grid, IconButton, Link, Typography } from '@mui/material'
import React from 'react'
import { SocialIcon } from 'react-social-icons'
import { constants } from '../Contants'

export default function Footer() {
    return (
        <Box className="footer" sx={{
            height: "20%",
            position: "absolute",
            bottom: "-20%",
            width: "100%",
            left: 0,
            bgcolor: "var(--primary-color)"
        }}>
            <Container maxWidth={false} sx={{ margin: "0", width: "100%", height: "100%" }}>
                <Box display="flex" justifyContent="space-evenly" alignItems="center" flexDirection="column" height="100%">
                    <Box>
                        <Typography variant='h6' sx={{
                            color: "lightgray",
                            textAlign: "center",
                            mt: 1,
                        }}>Our Social Media</Typography>
                        <Box display="flex" justifyContent="center" sx={{ mb: 1 }}>
                            <SocialIcon className='social-icon' url="https://www.instagram.com/arc6014/" target='_blank'></SocialIcon>
                            <SocialIcon className='social-icon' url="https://www.youtube.com/channel/UC0w6fZ6B1JiBYtjDN0xNdLQ" target='_blank'></SocialIcon>
                            <SocialIcon className='social-icon' url="https://www.linkedin.com/company/arc-robotics-club/about/" target='_blank'></SocialIcon>
                            <SocialIcon className='social-icon' url="https://discord.gg/kwc3t5yq9X" target='_blank'></SocialIcon>
                            <SocialIcon className='social-icon' url="https://github.com/ARC6014" target='_blank'></SocialIcon>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ justifySelf: "flex-end" }}>
                        <Typography variant='caption' sx={{
                            color: "lightgray",
                            textAlign: "center",
                            mr: 1,
                        }}>© 2023 ARC#6014. Made with ❤ in Istanbul, Turkey. <Link variant='caption' href='https://forms.gle/zAgVG7q3hCXbDBRcA' target="_blank" sx={{ color: "var(--secondary-color)" }}>Contact Us</Link></Typography>
                        <Typography variant='caption' sx={{
                            color: "lightgray",
                            textAlign: "center",
                            mr: 1,
                            fontSize: "0.5rem !important"
                        }}>Developed by Mehmet Reşat Halıcı and Eren Kural of ARC#6014.</Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
