import { createContext, useContext, useEffect, useState, Profiler } from "react";
import { supabase } from "../lib/helper/supabaseClient";
import { User } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { generateUsername } from "../tools/stringTools";

type Props = {
  children: React.ReactNode
}

export type Profile = {
  uid: string;
  username: string;
  avatar_url?: string;
  email?: string;
}

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const login = (email: string, password: string) => 
  supabase.auth.signInWithPassword({ email, password });

const AuthProvider = (props:Props) => {
    const [user, setUser]:any = useState(null);
    const [auth, setAuth] = useState(false);
    const [session, setSession]:any = useState();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile]:any = useState();

    const navigate = useNavigate();

    useEffect(() => {
      function getSession() {

        setLoading(false);

        let gotSession = localStorage.getItem("authSession")

        if (gotSession) {
          setSession(JSON.parse(gotSession))
          setUser(JSON.parse(gotSession))
          //@ts-ignore
          setUser(JSON.parse(gotSession).user)
        }

        const { data } = supabase.auth.onAuthStateChange((event, session) => {
          if (event === "SIGNED_IN") {
            setUser(session?.user);
            setSession(session);
            localStorage.setItem("authSession", JSON.stringify(session));
            setAuth(true);
            getProfile();
          } else if (event === "SIGNED_OUT") {
            setAuth(false);
            localStorage.removeItem("authSession");
            setSession(null);
            setProfile(null);
            setUser(null);
            navigate("/sign-up");
          }
          setLoading(false)
        });
        getProfile();
        return () => {
          data.subscription.unsubscribe();
        };
      }

      async function getProfile() {
        try {
          var usrr  = await supabase.auth.getUser();
          var usr = usrr?.data.user;
      
          if (usr) {
            let { data, error } = await supabase.from('profiles').select().eq('uid', usr.id).limit(1);
            if (error) {
              throw error;
            }
      
            if (data && data[0]) {
              var prof: Profile = {
                uid: data[0]["uid"],
                username: data[0]["username"],
                avatar_url: data[0]["avatar_url"],
                email: data[0]["email"],
              }
              setProfile(prof);
            } else {
              await createProfile(usr);
              return;
            }
          }
        }
        catch (err) {
          console.log(err);
        }
      }

      async function createProfile(usr: User) {
        try {
      
          if (usr) {
            const prof: Profile = {
              uid: usr.id,
              username: generateUsername(),
              email: usr.email,
            }
      
            if(usr.user_metadata["avatar_url"]) {
              prof.avatar_url = usr.user_metadata["avatar_url"];
            }
      
            if(usr.email) {
              prof.username = usr.email.split("@")[0];
            }
      
            if(usr.user_metadata["full_name"]) {
              prof.username = usr.user_metadata["full_name"];
            }
      
            console.log(usr.user_metadata);
      
      
            await supabase.from('profiles').upsert(prof);
      
            setProfile(prof);
          }
      
        } 
        catch (err) {
          console.log(err);
        }
      }

      getSession();
    }, []);

    return (
        <AuthContext.Provider value={{ user, profile, login, loading}}>{props.children}</AuthContext.Provider>
    );
}

export default AuthProvider;