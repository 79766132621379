import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function Page404() {
    
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <Box className="404page" display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: "100%", width: "100%"}}>
            <Typography variant="h3" fontFamily="Luckiest Guy" color="secondary" textAlign="center" fontWeight={700} sx={{padding: "0 2rem"}}>{t('404title')}</Typography>
            <Typography variant='subtitle2' color="white" textAlign="center" fontWeight={700} sx={{padding: "0 2rem"}}>{t('404message')}</Typography>
            <Button variant='contained' color='secondary' sx={{mt: "1rem"}} onClick={() => navigate("/")}>{t('returnButton')}</Button>
        </Box>
    )
}
