import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Appbar from './components/Appbar'
import About from './pages/About'
import Account from './pages/Account'
import Chat from './pages/Chat'
import Demo from './pages/Demo'
import Home from './pages/Home'
import Login from './pages/Login'
import ProfilePage from './pages/Profile'

import './Main.css'
import './components/Util.css'
import Footer from './components/Footer'
import useScreenOrientation from './lib/hooks/useScreenOrientation'
import { Backdrop, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { constants } from './Contants'
import Page404 from './pages/Page404'

export default function Main() {
  const navigate = useNavigate()
  const orientation = useScreenOrientation()

  const {t} = useTranslation()

  return (
    <div className="all-holder">
      <div className="main-holder" >
        <Backdrop sx={{bgcolor: "rgba(0,0,0,0.9)", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column" }}
          open={window.screen.width <= 932 && window.screen.height <= 430 && (orientation === "landscape-primary" || orientation === "landscape-secondary")}>
          <Typography>{t('landscapeError')}</Typography>
        </Backdrop>

        <Appbar />
        <div className="background"></div>
        <div className="page">
          <Routes>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/account" element={<Account />} />
            <Route path="/sign-up" element={<Login />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}
