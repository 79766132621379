import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'
import { MarkdownRenderer } from './MarkdownRenderer';

const Typewriter = (props:{ text: string, delay: number }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < props.text.length) {
      let delay = props.delay;  

      //@ts-ignore  
      if([
        ".",
        ",",
        ":",
        ";",
        "?",
        "!"
      ].includes(props.text[currentIndex+1])) {
        delay*=7;
      }

      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + props.text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, props.delay, props.text]);

  return <span>
    <MarkdownRenderer>{currentText}</MarkdownRenderer>
  </span>;
};

export default Typewriter;