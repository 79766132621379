import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';

import "./Profile.css";
import { useAuth } from "../context/AuthProvider";
import { supabase } from "../lib/helper/supabaseClient";

import { Profile } from "../context/AuthProvider";
import LoadingCheck from "../components/LoadingCheck";
import { useTranslation } from "react-i18next";

enum Status {
  error,
  success,
  none,
}

export default function ProfilePage() {
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false);

  //@ts-ignore
  const { profile, user } = useAuth();

  const [madeProfile, setMadeProfile] = useState(profile as Profile);

  const [status, setStatus] = useState(Status.none);

  useEffect(() => {
    setMadeProfile(profile);
  }, [profile]);

  return (
    <Box className="profile" height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center">
      <LoadingCheck open={loading || !user}></LoadingCheck>
      <Box
        className="profile-holder"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="prof" width="fit-content" alignItems="center" display="flex" flexDirection="column">
          <Paper
          variant="elevation"
          elevation={3}
            className="profile-card"
            sx={{
              width: "fit-content",
              minWidth: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: "1rem",
            }}
          >
            <Box
              className="avatar-upload"
              width="3rem"
              height="3rem"
              sx={{ position: "relative", margin: "0.5rem" }}
            >
              <input
                accept="image/*"
                className={"avatar-uploader"}
                id="contained-button-file"
                type="file"
                title=" "
                style={{
                  borderRadius: "100%",
                  color: "transparent",
                  opacity: 0,
                  zIndex: 2,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                onChange={uploadAvatar}
              />
              <Box
                className="avatar-overlay"
                sx={{
                  height: "100%",
                  width: "100%",
                  opacity: 0,
                  backgroundColor: "var(--secondary-color)",
                  position: "absolute",
                  zIndex: 3,
                  top: 0,
                  left: 0,
                  pointerEvents: "none",
                  borderRadius: "100%",
                }}
              ></Box>
              <IconButton
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  padding: "0 !important",
                  border: "3px solid black",
                }}
              >
                <Avatar
                  className="avatar"
                  alt={
                    madeProfile?.username ?? profile?.username ?? "Your Avatar"
                  }
                  src={
                    madeProfile?.avatar_url ??
                    profile?.avatar_url ??
                    require("../images/user.png")
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </IconButton>
            </Box>
            <TextField
              variant="standard"
              defaultValue={madeProfile?.username}
              placeholder={profile?.username}
              sx={{mr: "0.5rem"}}
              id="username-field"
              onChange={(e) => {
                setMadeProfile(
                  (mp) =>
                    ({
                      uid: mp.uid,
                      username: e.target.value,
                      avatar_url: mp.avatar_url,
                      email: mp.email,
                    } as Profile)
                );
              }}
            ></TextField>
          </Paper>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              width: "100% !important",
            }}
            onClick={() => saveChanges()}
          >
            {t('saveButton')}
          </Button>
          {status != Status.none && (
            <Alert
              severity={status == Status.success ? "success" : "error"}
              sx={{ width: "fit-content", mt: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
              action={
                <IconButton size="small" onClick={() => setStatus(Status.none)}>
                  <CloseIcon sx={{height: "1rem", width: "1rem"}}></CloseIcon>
                </IconButton>
              }
            >
              {status == Status.success 
              ? t('profileSuccess')
              : t('errorText')}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );

  async function saveChanges() {
    setLoading(true);
    const { data, error } = await supabase.from("profiles").upsert(madeProfile);
    setLoading(false);
    if (error) {
      setStatus(Status.error);
    } else {
      setStatus(Status.success);
    }
    console.log(status.toString());
  }

  async function uploadAvatar(e: any) {
    setLoading(true)

    //@₺s-ignore
    const avatarFile = e.target.files ? e.target.files[0] : null;
    if (avatarFile && user) {
      await supabase.storage
        .from("avatars")
        .remove(
          profile.avatar_url.slice(
            (
              "" +
              process.env.REACT_APP_SUPABASE_URL +
              "/storage/v1/object/public/avatars/"
            ).length
          )
        );

      const { data, error } = await supabase.storage
        .from("avatars")
        .upload("avatar-" + user.id + "-" + Date.now() + ".png", avatarFile, {
          cacheControl: "3600",
          upsert: true,
        });

      if (!data || error) {
        throw error;
      }

      const url = data["path"];

      setMadeProfile({
        uid: madeProfile.uid,
        username: madeProfile.username,
        avatar_url:
          process.env.REACT_APP_SUPABASE_URL +
          "/storage/v1/object/public/avatars/" +
          url,
        email: madeProfile.email,
      } as Profile);
    }
    setLoading(false);
  }
}
