import { Box, TextField, IconButton } from "@mui/material";
import React, { MouseEventHandler } from "react";
import useScreenOrientation from "../lib/hooks/useScreenOrientation";

import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";

import './InputBar.css';

interface Props {
  sendFunction: MouseEventHandler<HTMLButtonElement>;
  keyDownFunction: React.KeyboardEventHandler<HTMLDivElement>;
}

export default function (props: Props) {
  const orientation = useScreenOrientation();

  const { t } = useTranslation();

  return (
    <Box
      className="inputField"
      position="relative"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        bgcolor: "var(--tetriary-color)",
        borderRadius: "0.25rem",
      }}
    >
      <TextField
        id="messageInputField"
        className="small-tf"
        variant="standard"
        placeholder={t("inputHintText")}
        color="secondary"
        multiline
        maxRows={3}
        sx={{
          backgroundColor: "var(--tetriary-color)",
          padding: "0.25rem",
          borderRadius: "0.25rem",
          width: "calc(100% - 0.5rem)",
        }}
        onKeyDown={props.keyDownFunction}
      ></TextField>
      <IconButton onClick={props.sendFunction}>
        <SendIcon color="secondary"></SendIcon>
      </IconButton>
    </Box>
  );
}
