import { Backdrop, CircularProgress, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    open: boolean
}

export default function LoadingCheck(props: Props) {
  const {t} = useTranslation();

  return (
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 , display:"flex", flexDirection:"column"}}
        open={props.open}
      >
        <CircularProgress color="secondary" size="4rem" />
        <Typography variant="subtitle1" fontWeight={700}>{t('loadingTextSub')}</Typography>
        <Typography variant="caption">{t('loadingTextCap1')}<Link color="secondary" href="/sign-up">{t('loadingTextCapLink')}</Link>{t('loadingTextCap2')}</Typography>
      </Backdrop>
  )
}
