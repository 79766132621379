import { Box } from "@mui/material";
import React from "react";

import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "../lib/helper/supabaseClient";

import "./Login.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

export default function Login() {
  const {t} = useTranslation()

  const navigate = useNavigate()

  //@ts-ignore
  const {user} = useAuth()

  if(user) navigate("/")

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
      className="login"
      width="100%"
    >
      <Box
        className="auth-holder"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
        sx={{
          background: "color-mix(in  srgb, var(--primary-color) 90%, transparent)",
          padding: "2rem",
          width: "50%",
          borderRadius: "1rem",
        }}
      >
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  defaultButtonBackground: "var(--primary-color)",
                  brand: "var(--secondary-color)",
                  brandAccent: "var(--secondary-color-accent)",
                },
              },
            },
          }}
          theme="dark"
          providers={["google"]}
          localization={{
            variables: {
              sign_in: {
                email_label: t('emailAdd'),
                password_label: t('crtPwd'),
                email_input_placeholder: t('yourEmail'),
                password_input_placeholder: t('yourPwd'),
                button_label: t('signUp'),
                loading_button_label: t('signingUp'),
                social_provider_text: t('signInWith'),
                link_text: t('dontHaveAcc'),
              },
              sign_up: {
                email_label: t('emailAdd'),
                password_label: t('yourPwd'),
                email_input_placeholder: t('yourEmail'),
                password_input_placeholder: t('yourPwd'),
                button_label: t('signIn'),
                loading_button_label: t('signingIn'),
                social_provider_text: t('signInWith'),
                link_text: t('alreadyHaveAcc'),

              },
              magic_link: {
                email_input_label: t('emailAdd'),
                email_input_placeholder: t('yourEmail'),
                button_label: t('signIn'),
                loading_button_label: t('signingIn'),
                link_text: t('magicLink'),
                confirmation_text: t('magicCheck')

              },
              forgotten_password: {
                email_label: t('emailAdd'),
                password_label: t('yourPwd'),
                email_input_placeholder: t('yourEmail'),
                button_label: t('pwdIns'),
                loading_button_label: t('sendingIns'),
                link_text: t('forgotPwd'),
                confirmation_text: t('resetCheck')
              },
              update_password: {
                password_label: t('newPwd'),
                password_input_placeholder: t('yourNewPwd'),
                button_label: t('updatePwd'),
                loading_button_label: t('updatingPwd'),
                confirmation_text: t('updatedPwd')
              },
              verify_otp: {
                email_input_label: t('emailAdd'),
                email_input_placeholder: t('yourEmail'),
                phone_input_label: t('phone'),
                phone_input_placeholder: t('yourPhone'),
                token_input_label: t('token'),
                token_input_placeholder: t('yourToken'),
                button_label: t('verifyToken'),
                loading_button_label: t('signingIn')
              }
            }
          }}
        ></Auth>
      </Box>
    </Box>
  );
}
