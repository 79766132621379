import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import { constants } from '../Contants';
import { useAuth } from '../context/AuthProvider';
import { supabase } from '../lib/helper/supabaseClient';
import { useTranslation } from 'react-i18next';

import { Sidebar } from 'react-pro-sidebar';
import { Menu as MenuR, MenuItem as MenuItemR } from 'react-pro-sidebar';

export default function Appbar() {
    const navigate = useNavigate();

    const { t } = useTranslation();

    //@ts-ignore
    const { profile, login, user } = useAuth();

    const [sidebarOpen, setSidebarOpen] = useState(true);

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const oldAppbar = (
        <AppBar className='appbar' position="sticky" sx={{ height: "3.5rem" }}>
            <Container maxWidth={false} sx={{ margin: "0", height: "100%", width: "100%" }}>
                <Toolbar disableGutters sx={{ height: "100%", minHeight: "unset" }}>
                    <Box
                        component="img"
                        sx={{
                            height: "3rem",
                            width: "3rem",
                            cursor: "pointer",
                        }}
                        alt="Arcinator Logo."
                        src={require("../images/logoFull.png")}
                        onClick={() => navigate("/")}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            ml: 1,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Alfa Slab One',
                            fontWeight: 500,
                            letterSpacing: '.05rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            fontSize: '1.5rem',
                        }}
                    >
                        ARCINATOR
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {constants.pages.map((page) => (
                                <MenuItem key={page} onClick={() => {
                                    navigate(page.toLowerCase())
                                    handleCloseUserMenu()
                                }}>
                                    <Typography textAlign="center">{t(page)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AutoAwesomeIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} onClick={() => navigate("/")} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'Alfa Slab One',
                            fontWeight: 500,
                            letterSpacing: '.05rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        ARCINATOR
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {constants.pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => {
                                    navigate(page.toLowerCase())
                                    handleCloseUserMenu()
                                }}
                                sx={{
                                    my: 2, color: 'white', display: 'block',
                                    /*'&:hover': {
                                        bgcolor: "var(--secondary-color)"
                                    }*/
                                }}
                            >
                                {t(page)}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title={profile?.name ?? "My Account"}>
                            <IconButton onClick={(event) => {
                                if (user) {
                                    handleOpenUserMenu(event)
                                } else {
                                    navigate("sign-up")
                                }
                            }} sx={{ p: 0 }}>
                                <Avatar alt={profile?.username ?? "Your Avatar"} src={profile?.avatar_url ?? require("../images/user.png")} sx={{ height: "2.5rem", width: "2.5rem" }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {constants.settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => {
                                    if (setting === "logout") {
                                        supabase.auth.signOut();
                                    } else {
                                        navigate(setting.toLowerCase())
                                    }
                                    handleCloseUserMenu()
                                }}>
                                    <Typography textAlign="center">{t(setting)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )

    const newSidebarTest = (
        <Sidebar style={{ height: "100%", position: "fixed" }} collapsed={!sidebarOpen}>
            <MenuR style={{ height: "100%"}}>
                <MenuItemR
                    icon={<MenuOutlinedIcon />}
                    onClick={() => {
                        setSidebarOpen(!sidebarOpen);
                    }}
                    style={{ textAlign: "center" }}
                >
                    {" "}
                    <h2>Admin</h2>
                </MenuItemR>

                <MenuItemR icon={<HomeOutlinedIcon />}>Home</MenuItemR>
                <MenuItemR icon={<PeopleOutlinedIcon />}>Team</MenuItemR>
                <MenuItemR icon={<ContactsOutlinedIcon />}>Contacts</MenuItemR>
                <MenuItemR icon={<ReceiptOutlinedIcon />}>Profile</MenuItemR>
                <MenuItemR icon={<HelpOutlineOutlinedIcon />}>FAQ</MenuItemR>
                <MenuItemR icon={<CalendarTodayOutlinedIcon />}>Calendar</MenuItemR>
            </MenuR>
        </Sidebar>
    )

    return oldAppbar;
}
